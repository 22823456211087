import Axios from 'axios'
import router from 'vue-router'
import Vue from 'vue'

const SUCESS_CODE = ['100000', 200, '200']

const instance = Axios.create({
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  // },
  timeout: 60000,
})

//添加请求拦截器
instance.interceptors.request.use(function(config){

  return config;
},function(error){
  //请求错误时做些事
  return Promise.reject(error);
});

//添加响应拦截器
instance.interceptors.response.use((response)=>{
  const resData = response.data
  if (SUCESS_CODE.includes(resData.code)) {
    return response.data;
  } else {
    Vue.prototype.$message.error(resData.message || resData.data);

    return response.data;
  }

},(error)=>{
  // //请求错误时做些事
  Vue.prototype.$message.error('接口连接失败。');
  // Vue.prototype.$message({
  //   message: "接口连接失败。",
  //   type: "error",
  //   showClose:true
  // });
  return Promise.reject(error);
});

export default instance