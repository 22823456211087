import request from '@/utils/axiosConfig.js'
import { root } from '../config'

export const getNews = (params) => {
  return request({
    url: root + 'haiou/page',
    method: 'GET',
    params: {
      className: 'PC',
      ...params
    }
  })
}

export const getNewsById = (params) => {
  return request({
    url: root + 'haiou/one',
    method: 'GET',
    params
  })
}